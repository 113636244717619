<template>
  <img :class="$options.name" src="/ethz_logo_black.svg" />
</template>

<script>
export default {
  name: "TheLogo",
};
</script>

<style src="./TheLogo.scss" lang="scss" scoped />
