<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<!-- Global Styles -->
<style src="./App.scss" lang="scss" />
