<template>
  <div :class="$options.name">
    <button
      :class="[`${$options.name}__button`, { 'is-active': l.isActive }]"
      v-for="l in availableLangs"
      :key="l.id"
      @click="l.clickHandler(l.id)"
    >
      {{ l.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: "LangSwitcher",
  computed: {
    availableLangs() {
      return this.$i18n.availableLocales.map((l) => ({
        id: l,
        name: l.toUpperCase(),
        isActive: this.$i18n.locale === l,
        clickHandler: (l) => this.setLang(l),
      }));
    },
  },
  mounted() {
    document.documentElement.setAttribute("lang", this.$i18n.locale);
  },
  watch: {
    "$i18n.locale"(locale) {
      document.documentElement.setAttribute("lang", locale);
    },
  },
  methods: {
    setLang(lang) {
      if (this.$i18n.availableLocales.includes(lang)) {
        this.$i18n.locale = lang;
        if (!this.$route.path.includes(lang)) {
          this.$router.replace({
            path: lang,
          });
        }
      }
    },
  },
};
</script>

<style src="./LangSwitcher.scss" lang="scss" scoped />
