<template>
  <svg
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_407_357)">
      <rect width="27" height="23" fill="white" />
      <path
        d="M11.1684 1.86794C11.1016 1.83835 11.0275 1.82837 10.9551 1.83922C10.8827 1.85008 10.815 1.88129 10.7603 1.92907L4.04835 7.78676H0.379314C0.278714 7.78676 0.182235 7.82591 0.111099 7.89561C0.0399642 7.9653 0 8.05983 0 8.1584V14.8562C-1.71336e-07 14.9549 0.0399415 15.0495 0.111057 15.1193C0.182172 15.1891 0.27865 15.2284 0.379314 15.2286H4.0175L10.761 21.0883C10.8159 21.1356 10.8834 21.1664 10.9557 21.177C11.0279 21.1876 11.1017 21.1776 11.1683 21.1481C11.2349 21.1187 11.2914 21.0711 11.3311 21.011C11.3707 20.951 11.3919 20.881 11.392 20.8094V2.20729C11.392 2.13556 11.3708 2.06538 11.331 2.00517C11.2912 1.94497 11.2345 1.89732 11.1677 1.86794H11.1684Z"
        fill="black"
      />
      <path
        d="M15.0361 6.12702C14.8914 6.05966 14.7255 6.05042 14.574 6.10128C14.4224 6.15214 14.2972 6.25906 14.225 6.3992C14.1529 6.53933 14.1395 6.70156 14.1879 6.85121C14.2362 7.00086 14.3423 7.12606 14.4836 7.20005C15.2981 7.60151 15.9823 8.21733 16.4596 8.97845C16.9369 9.73957 17.1883 10.6159 17.1858 11.5092C17.1833 12.4025 16.9268 13.2774 16.4452 14.0359C15.9635 14.7944 15.2758 15.4065 14.459 15.8035C14.3198 15.8777 14.2153 16.0018 14.1674 16.1498C14.1195 16.2979 14.1319 16.4583 14.202 16.5976C14.2722 16.7368 14.3946 16.8441 14.5437 16.8969C14.6928 16.9496 14.8569 16.9438 15.0017 16.8806C16.0219 16.3839 16.8808 15.6187 17.4823 14.6707C18.0837 13.7228 18.404 12.6296 18.4073 11.5134C18.4106 10.3972 18.0967 9.30216 17.5008 8.35086C16.9049 7.39956 16.0505 6.62954 15.0333 6.12702H15.0361Z"
        fill="black"
      />
      <path
        d="M18.2622 3.12699C18.1967 3.0759 18.1211 3.0385 18.0402 3.01711C17.9593 2.99571 17.8748 2.99078 17.7919 3.00261C17.709 3.01445 17.6294 3.04279 17.5581 3.0859C17.4868 3.12901 17.4253 3.18598 17.3773 3.25328C17.3294 3.32058 17.296 3.3968 17.2792 3.47723C17.2625 3.55766 17.2627 3.6406 17.2799 3.72094C17.2971 3.80127 17.331 3.8773 17.3793 3.94434C17.4277 4.01138 17.4895 4.068 17.5611 4.11072C18.7752 4.94466 19.7658 6.05364 20.4484 7.3433C21.1311 8.63296 21.4856 10.0651 21.4819 11.5179C21.4782 12.9707 21.1163 14.4011 20.4271 15.6873C19.7378 16.9736 18.7416 18.0777 17.5232 18.9057C17.457 18.9508 17.4005 19.0082 17.357 19.0747C17.3134 19.1412 17.2837 19.2155 17.2694 19.2932C17.2552 19.371 17.2567 19.4508 17.2739 19.5279C17.2911 19.6051 17.3236 19.6782 17.3697 19.7431C17.4157 19.808 17.4743 19.8633 17.5422 19.906C17.6101 19.9486 17.6859 19.9778 17.7652 19.9918C17.8446 20.0057 17.926 20.0042 18.0048 19.9874C18.0835 19.9705 18.1582 19.9387 18.2244 19.8935C19.6051 18.9551 20.7341 17.7038 21.5151 16.246C22.2962 14.7882 22.7062 13.1671 22.7104 11.5207C22.7145 9.87424 22.3126 8.25119 21.5389 6.78965C20.7651 5.32811 19.6425 4.07134 18.2664 3.12631L18.2622 3.12699Z"
        fill="black"
      />
      <path
        d="M21.3405 0.127032C21.2123 0.0287393 21.0495 -0.0156246 20.8879 0.00369985C20.7263 0.0230243 20.5791 0.104454 20.4788 0.230076C20.3785 0.355697 20.3332 0.51522 20.3529 0.673551C20.3726 0.831883 20.4557 0.976052 20.584 1.07435C22.2009 2.31172 23.5087 3.89399 24.4079 5.70088C25.3071 7.50777 25.774 9.49173 25.7732 11.502C25.7724 13.5122 25.3039 15.4958 24.4032 17.302C23.5026 19.1082 22.1935 20.6894 20.5755 21.9255C20.4473 22.0238 20.3642 22.1678 20.3444 22.3261C20.3246 22.4844 20.3698 22.6439 20.47 22.7695C20.5703 22.8951 20.7173 22.9766 20.8788 22.9959C21.0404 23.0153 21.2032 22.9711 21.3314 22.8729C23.0964 21.5244 24.5244 19.7995 25.5069 17.8292C26.4894 15.8589 27.0005 13.695 27.0014 11.5021C27.0023 9.30923 26.4929 7.14499 25.512 5.17392C24.5311 3.20285 23.1044 1.47682 21.3405 0.127032Z"
        fill="black"
      />
      <line
        x1="0.625"
        y1="-0.625"
        x2="33.8417"
        y2="-0.625"
        transform="matrix(0.765287 0.643689 -0.658836 0.752287 -0.207031 0.814209)"
        stroke="black"
        stroke-width="1.25"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_407_357">
        <rect width="27" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
