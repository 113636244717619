import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import { createRouter, createWebHistory } from "vue-router";
import messages from "./i18n/translations";

import App from "./App.vue";
import ViewImprint from "./views/Imprint.vue";
import ViewCow from "./views/Cow.vue";
// import "./registerServiceWorker";

const routes = [
  {
    path: "/en/imprint",
    name: "imprint-en",
    component: ViewImprint,
    meta: { lang: "en" },
  },
  {
    path: "/fr/mentions-legales",
    name: "imprint-fr",
    component: ViewImprint,
    meta: { lang: "fr" },
  },
  {
    path: "/it/impronta",
    name: "imprint-it",
    component: ViewImprint,
    meta: { lang: "it" },
  },
  {
    path: "/impressum",
    name: "imprint-de",
    component: ViewImprint,
    meta: { lang: "de" },
  },
  {
    path: "/de/impressum",
    component: ViewImprint,
    redirect: "/impressum",
  },
  { path: "/en", name: "home-en", component: ViewCow, meta: { lang: "en" } },
  { path: "/fr", name: "home-fr", component: ViewCow, meta: { lang: "fr" } },
  { path: "/it", name: "home-it", component: ViewCow, meta: { lang: "it" } },
  { path: "/", name: "home-de", component: ViewCow, meta: { lang: "de" } },
  { path: "/de", component: ViewCow, redirect: "/" },
  { path: "/:catchAll(.*)", component: ViewCow, redirect: "/" },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

const i18n = createI18n({
  locale: "de", // set locale
  fallbackLocale: "de", // set fallback locale
  messages, // set locale messages
});

const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(i18n);
app.mount("#app");

router.beforeEach((to, from, next) => {
  const localeToSet = to.meta.lang;
  if (i18n.global.locale !== localeToSet) {
    i18n.global.locale = localeToSet;
  }

  return next();
});
