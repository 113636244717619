<template>
  <div v-if="$i18n.locale === 'de'">
    <h2>Herausgeberin</h2>
    <p>
      Dr. Melissa Terranova, Leiterin Forschung AgroVet-Strickhof <br />
      Mutian Niu, Professor für Tierernährung, ETH Zürich, D-USYS
    </p>
    <h2>Idee</h2>
    <p>Melissa Terranova <br />Tobias Keller <br />Emma Lindberg</p>
    <h2>Organisation und Planung</h2>
    <p>Sophie Graf / Tobias Keller, <br />Kommunikation D-USYS</p>

    <h2>Technischer Kontakt</h2>
    <p>
      <a href="mailto:kommunikation@usys.ethz.ch">kommunikation@usys.ethz.ch</a>
    </p>
    <h2>Adresse</h2>
    <p>
      ETH Zürich <br />
      D-USYS <br />
      Universitätstrasse 8 – 16 <br />
      8092 Zürich <br />
      <a href="https://usys.ethz.ch">www.usys.ethz.ch</a>
    </p>
    <h2>Design und Umsetzung</h2>
    <p>
      <a href="https://www.wearelucid.ch/">Lucid</a>
      <br />
      <a href="https://www.infografik.ch/">Infografik.ch</a>
    </p>

    <h2>Copyright</h2>
    <p>
      Sämtliche Online-Dokumente und Webseiten sowie ihre Teile sind
      urheberrechtlich geschützt und dürfen nur zum privaten, wissenschaftlichen
      und nicht gewerblichen Gebrauch kopiert und ausgedruckt werden.
    </p>
    <p>
      Ohne vorgängige schriftliche Erlaubnis der ETH Zürich dürfen die Dokumente
      und Webseiten sowie ihre Teile weder vervielfältigt noch auf anderen
      Servern gespeichert, in Newsgroups oder Online-Dienste eingespeist oder
      auf einer CD-ROM oder anderen Datenträgern gespeichert werden. Dies gilt
      insbesondere für das Logo der ETH Zürich.
    </p>
    <p>
      Die Zwischenspeicherung in einem Cache oder auf einem Proxy-Server zur
      Optimierung der Zugriffsgeschwindigkeit ist gestattet. Das Zitieren der
      Dokumente und Webseiten unter korrekten Quellenangaben sowie das Setzen
      von Links auf unsere Website ist ausdrücklich erlaubt.
    </p>
    <p>«ETH» ist eine eingetragene Marke der ETH Zürich in der Schweiz</p>

    <h2>Datenschutz</h2>
    <p>
      <a href="https://usys.ethz.ch/footer/datenschutz.html"
        >Es gelten die allgemeinen rechtlichen Hinweise der ETH Zürich.</a
      >
    </p>
  </div>
  <div v-else-if="$i18n.locale === 'en'">
    <h2>Publisher</h2>
    <p>
      Dr. Melissa Terranova, Head of Research AgroVet-Strickhof <br />
      Mutian Niu, Professor for Animal Nutrition, ETH Zurich, D-USYS
    </p>
    <h2>Idea</h2>
    <p>Melissa Terranova <br />Tobias Keller <br />Emma Lindberg</p>
    <h2>Organisation and planning</h2>
    <p>Sophie Graf / Tobias Keller, <br />Communication D-USYS</p>

    <h2>Technical contact</h2>
    <p>
      <a href="mailto:kommunikation@usys.ethz.ch">kommunikation@usys.ethz.ch</a>
    </p>
    <h2>Address</h2>
    <p>
      ETH Zurich <br />
      D-USYS <br />
      Universitätstrasse 8 – 16 <br />
      8092 Zurich <br />
      <a href="https://usys.ethz.ch/en/">www.usys.ethz.ch</a>
    </p>
    <h2>Design and implementation</h2>
    <p>
      <a href="https://www.wearelucid.ch/">Lucid</a>
      <br />
      <a href="https://www.infografik.ch/">Infografik.ch</a>
    </p>

    <h2>Copyright</h2>
    <p>
      All online documents and web pages as well as their parts are protected by
      copyright and may only be copied and printed for private, scientific and
      non-commercial use.
    </p>
    <p>
      Without prior written permission from ETH Zurich, the documents and web
      pages as well as their parts may neither be reproduced nor stored on other
      servers, fed into newsgroups or online services or saved on a CD-ROM or
      other data carriers. This applies in particular to the ETH Zurich logo.
    </p>
    <p>
      Intermediate storage in a cache or on a proxy server to optimise access
      speed is permitted. The citation of documents and web pages with correct
      source information as well as the setting of links to our website is
      expressly permitted.
    </p>
    <p>ETH is a registered trademark of ETH Zurich in Switzerland.</p>

    <h2>Data protection</h2>
    <p>
      <a href="https://usys.ethz.ch/en/footer/data-protection.html"
        >The general legal conditions of ETH Zurich apply.</a
      >
    </p>
  </div>
  <div v-else-if="$i18n.locale === 'it'">
    <h2>Editore</h2>
    <p>
      Dr. Melissa Terranova, Capo della Ricerca AgroVet-Strickhof <br />
      Mutian Niu, Professore di nutrizione animale, ETH Zurigo, D-USYS
    </p>
    <h2>Idea</h2>
    <p>Melissa Terranova <br />Tobias Keller <br />Emma Lindberg</p>
    <h2>Organizzazione e pianificazione</h2>
    <p>Sophie Graf / Tobias Keller, <br />Comunicazione D-USYS</p>

    <h2>Contatto tecnico</h2>
    <p>
      <a href="mailto:kommunikation@usys.ethz.ch">kommunikation@usys.ethz.ch</a>
    </p>
    <h2>Indirizzo</h2>
    <p>
      ETH Zurigo <br />
      D-USYS <br />
      Universitätstrasse 8 – 16 <br />
      8092 Zurigo <br />
      <a href="https://usys.ethz.ch">www.usys.ethz.ch</a>
    </p>
    <h2>Progettazione e implementazione</h2>
    <p>
      <a href="https://www.wearelucid.ch/">Lucid</a>
      <br />
      <a href="https://www.infografik.ch/">Infografik.ch</a>
    </p>

    <h2>Copyright</h2>
    <p>
      Tutti i documenti online e le pagine web e le loro parti sono protetti da
      copyright e possono essere copiati e stampati solo per uso privato,
      scientifico e non commerciale.
    </p>
    <p>
      Senza previa autorizzazione scritta dell'ETH Zurich, i documenti e le
      pagine web e le loro parti non possono essere riprodotti né memorizzati su
      altri server, inseriti in newsgroup o servizi online o salvati su un
      CD-ROM o altri supporti dati. Questo vale in particolare per il logo
      dell'ETH Zurich.
    </p>
    <p>
      È consentita la memorizzazione intermedia in una cache o su un server
      proxy per ottimizzare la velocità di accesso. La citazione di documenti e
      pagine web con informazioni corrette sulla fonte e l'inserimento di link
      al nostro sito web sono espressamente permessi.
    </p>
    <p>ETH è un marchio registrato di ETH Zurich in Svizzera.</p>

    <h2>Protezione dei dati</h2>
    <p>
      <a href="https://usys.ethz.ch/footer/datenschutz.html"
        >Si applicano le condizioni legali generali dell'ETH Zurich.</a
      >
    </p>
  </div>
  <div v-else-if="$i18n.locale === 'fr'">
    <h2>Éditeur</h2>
    <p>
      Dr Melissa Terranova, responsable de la recherche AgroVet-Strickhof <br />
      Mutian Niu, Professeur de nutrition animale, ETH Zurich, D-USYS
    </p>
    <h2>Idée</h2>
    <p>Melissa Terranova <br />Tobias Keller <br />Emma Lindberg</p>
    <h2>Organisation et planification</h2>
    <p>Sophie Graf / Tobias Keller, <br />Communication D-USYS</p>

    <h2>Contact technique</h2>
    <p>
      <a href="mailto:kommunikation@usys.ethz.ch">kommunikation@usys.ethz.ch</a>
    </p>
    <h2>Adresse</h2>
    <p>
      ETH Zurich <br />
      D-USYS <br />
      Universitätstrasse 8 – 16 <br />
      8092 Zurich <br />
      <a href="https://usys.ethz.ch">www.usys.ethz.ch</a>
    </p>
    <h2>Conception et réalisation</h2>
    <p>
      <a href="https://www.wearelucid.ch/">Lucid</a>
      <br />
      <a href="https://www.infografik.ch/">Infografik.ch</a>
    </p>

    <h2>Copyright</h2>
    <p>
      Tous les documents en ligne et les pages web ainsi que leurs parties sont
      protégés par des droits d'auteur et ne peuvent être copiés et imprimés que
      pour un usage privé, scientifique et non commercial.
    </p>
    <p>
      Sans l'autorisation écrite préalable de l'ETH Zurich, les documents et les
      pages web ainsi que leurs parties ne peuvent être ni reproduits, ni
      enregistrés sur d'autres serveurs, ni introduits dans des groupes de
      discussion ou des services en ligne, ni stockés sur un CD-ROM ou sur
      d'autres supports de données. Cela vaut en particulier pour le logo de
      l'ETH Zurich.
    </p>
    <p>
      Le stockage temporaire dans un cache ou sur un serveur proxy pour
      optimiser la vitesse d'accès est autorisé. Il est expressément permis de
      citer les documents et les pages web en indiquant correctement la source
      et de créer des liens vers notre site web.
    </p>
    <p>ETH est une marque déposée de l'ETH Zurich en Suisse.</p>

    <h2>Protection des données</h2>
    <p>
      <a href="https://usys.ethz.ch/footer/datenschutz.html"
        >Les informations juridiques générales de l'ETH Zurich s'appliquent.</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "ImprintContent",
};
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 2em;
}
</style>
