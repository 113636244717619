<template>
  <button :class="$options.name" @click="store.toggleSound">
    <span aria-hidden="true" :class="`${$options.name}__icon`">
      <IconSoundOn v-if="store.soundEnabled" />
      <IconSoundOff v-else />
    </span>
    {{ store.soundEnabled ? $t("sound.off") : $t("sound.on") }}
  </button>
</template>

<script>
import { useStore } from "@/stores/main";
import IconSoundOn from "@/components/svg/IconSoundOn.vue";
import IconSoundOff from "@/components/svg/IconSoundOff.vue";

export default {
  name: "MuteButton",
  components: {
    IconSoundOn,
    IconSoundOff,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>

<style src="./MuteButton.scss" lang="scss" scoped />
