<template>
  <div v-if="$i18n.locale === 'de'">
    <h2>
      Was müssen unsere Kühe fressen, damit sie möglichst wenig Treibhausgase
      ausstossen?
    </h2>
    <p>
      Unsere Kühe sind einzigartig, denn sie können aus für uns Menschen nicht
      geniessbaren Futtern Milch und Fleisch produzieren. Doch sie stossen das
      Treibhausgas Methan aus. Das Gas ist in der Atmosphäre zwar kurzlebiger
      als CO₂, aber rund 25-mal schädlicher für das Klima. Um die 300 Liter
      Methan stösst ein Rindvieh täglich aus. Damit belasten unsere Kühe die
      Treibhausgasbilanz der Landwirtschaft. Deshalb forschen wir an der ETH
      Zürich nach neuen Futterarten. Wir wollen Kühe und ihre Fütterung
      klimafreundlicher machen und damit jedes Stück Käse, Butter oder
      Rinderbraten, das auf den Tisch kommt.
    </p>
    <p>
      Kühe könnten zum Beispiel auch Reste aus der Lebensmittelherstellung
      fressen. Diese sind für uns nicht interessant, schmecken aber den Kühen
      und produzieren erst noch weniger Treibhausgase.
    </p>
    <p>
      Welche Futterart hat den besten Effekt? Teste die verschiedenen
      Futtermittel und beobachte, welchen Einfluss sie auf den Methanausstoss
      und die Milchproduktion haben.
    </p>
    <p>
      Prof. Dr. Michael Kreuzer <br />
      Prof. Dr. Mutian Niu <br />
      <a href="https://an.ias.ethz.ch">Gruppe Tierernährung</a>
    </p>
    <p>
      ETH Zürich <br />
      <a href="https://usys.ethz.ch"
        >Departement für Umweltsystemwissenschaften D-USYS</a
      >
      <br />
      <a href="https://www.ias.ethz.ch/de/">Institut für Agrarwissenschaften</a>
      in Zusammenarbeit mit
      <a href="https://www.agrovet-strickhof.ch">AgroVet-Strickhof</a>
    </p>
  </div>
  <div v-else-if="$i18n.locale === 'en'">
    <h2>
      What do our cows have to eat so that they emit as few greenhouse gases as
      possible?
    </h2>
    <p>
      Our cows are unique because they can produce milk and meat from fodder
      that is not edible for us humans. But they emit the greenhouse gas
      methane. This gas is shorter-lived in the atmosphere than CO₂, but it is
      around 25 times more harmful to the climate. A cow emits around 300 litres
      of methane every day. This means that our cows are a burden on the
      greenhouse gas balance of agriculture. That is why we are researching new
      types of feed at ETH Zurich. We want to make cows and their feeding more
      climate-friendly and thus every piece of cheese, butter or roast beef that
      comes onto the table.
    </p>
    <p>
      For example, cows could also eat leftovers from food production. These are
      of no interest to us, but taste good to the cows and produce fewer
      greenhouse gases.
    </p>
    <p>
      Which type of feed has the best effect? Test the different feeds and
      observe what influence they have on methane emissions and milk production.
    </p>
    <p>
      Prof. Dr. Michael Kreuzer <br />
      Prof. Dr. Mutian Niu <br />
      <a href="https://an.ias.ethz.ch/en/">Animal Nutrition Group</a>
    </p>
    <p>
      ETH Zurich <br />
      <a href="https://usys.ethz.ch/en/"
        >Department of Environmental Systems Sciences D-USYS</a
      >
      <br />
      <a href="https://www.ias.ethz.ch">Institute of Agricultural Sciences</a>
      in cooperation with
      <a href="https://www.agrovet-strickhof.ch/en/">AgroVet-Strickhof</a>
    </p>
  </div>
  <div v-else-if="$i18n.locale === 'it'">
    <h2>
      Cosa devono mangiare le nostre mucche per emettere meno gas serra
      possibile?
    </h2>
    <p>
      Le nostre mucche sono uniche perché possono produrre latte e carne da un
      foraggio che non è commestibile per noi umani. Ma emettono il gas serra
      metano. Questo gas ha una vita più breve nell'atmosfera rispetto alla CO₂,
      ma è circa 25 volte più dannoso per il clima. Una mucca emette circa 300
      litri di metano ogni giorno. Questo significa che le nostre mucche sono un
      peso per il bilancio dei gas serra dell'agricoltura. Questo è il motivo
      per cui all'ETH di Zurigo stiamo ricercando nuovi tipi di alimentazione.
      Vogliamo rendere le mucche e la loro alimentazione più rispettose del
      clima e quindi ogni pezzo di formaggio, burro o roast beef che arriva in
      tavola.
    </p>
    <p>
      Per esempio, le mucche potrebbero anche mangiare gli avanzi della
      produzione di cibo. Questi non sono di alcun interesse per noi, ma hanno
      un buon sapore per le mucche e producono meno gas serra.
    </p>
    <p>
      Quale tipo di mangime ha il miglior effetto? Prova i diversi mangimi e
      osserva quale influenza hanno sulle emissioni di metano e sulla produzione
      di latte.
    </p>
    <p>
      Prof. Dr. Michael Kreuzer <br />
      Prof. Dr. Mutian Niu <br />
      <a href="https://an.ias.ethz.ch">Gruppo di nutrizione animale</a>
    </p>
    <p>
      ETH Zurigo <br />
      <a href="https://usys.ethz.ch"
        >Dipartimento di Scienze dei Sistemi Ambientali D-USYS</a
      >
      <br />
      <a href="https://www.ias.ethz.ch/de/">Istituto per le Scienze Agrarie</a>
      in collaborazione con
      <a href="https://www.agrovet-strickhof.ch">AgroVet-Strickhof</a>
    </p>
  </div>
  <div v-else-if="$i18n.locale === 'fr'">
    <h2>
      Que doivent manger nos vaches pour qu'elles émettent le moins de gaz à
      effet de serre possible ?
    </h2>
    <p>
      Nos vaches sont uniques car elles peuvent produire du lait et de la viande
      à partir de fourrages qui ne sont pas comestibles pour nous, les humains.
      Mais elles émettent du méthane, un gaz à effet de serre. Ce gaz a une
      durée de vie plus courte dans l'atmosphère que le CO₂, mais il est environ
      25 fois plus nocif pour le climat. Une vache émet environ 300 litres de
      méthane par jour. Cela signifie que nos vaches pèsent sur le bilan des gaz
      à effet de serre de l'agriculture. C'est pourquoi nous recherchons de
      nouveaux types d'aliments pour animaux à l'ETH Zurich. Nous voulons rendre
      les vaches et leur alimentation plus respectueuses du climat et ainsi
      chaque morceau de fromage, de beurre ou de rosbif qui arrive sur la table.
    </p>
    <p>
      Par exemple, les vaches pourraient aussi manger les restes de la
      production alimentaire. Ceux-ci ne présentent aucun intérêt pour nous,
      mais ont bon goût pour les vaches et produisent moins de gaz à effet de
      serre.
    </p>
    <p>
      Quel type d'aliment a le meilleur effet ? Teste les différents types
      d'aliments et observe leur influence sur les émissions de méthane et la
      production de lait.
    </p>
    <p>
      Prof. Dr. Michael Kreuzer <br />
      Prof. Dr. Mutian Niu <br />
      <a href="https://an.ias.ethz.ch">Groupe de nutrition animale</a>
    </p>
    <p>
      ETH Zurich <br />
      <a href="https://usys.ethz.ch"
        >Département de la science des systèmes environnementaux D-USYS</a
      >
      <br />
      <a href="https://www.ias.ethz.ch/fr/"
        >Institut des sciences agronomiques</a
      >
      en collaboration avec
      <a href="https://www.agrovet-strickhof.ch">AgroVet-Strickhof</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "MainTextContent",
};
</script>
