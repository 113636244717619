<template>
  <div :class="$options.name">
    <FeedMeCta />
    <div :class="`${$options.name}__animation-wrapper`">
      <div
        :class="`${$options.name}__animation ${$options.name}__animation--background`"
        ref="backgroundAnimationEl"
      />
      <div
        :class="`${$options.name}__animation ${$options.name}__animation--main`"
        ref="mainAnimationEl"
      />
    </div>
    <FoodDraggables @dropped="onDropped" />
  </div>
</template>

<script>
import { onMounted, ref, reactive, onBeforeUnmount } from "vue";
import { useStore } from "@/stores/main";
import emitter from "@/services/emitter";
import FeedMeCta from "@/components/FeedMeCta.vue";
import FoodDraggables from "@/components/FoodDraggables.vue";
import { loadAnimation } from "lottie-web";
import { useSound } from "@vueuse/sound";
import cowSound1 from "@/assets/sounds/mixkit-cow-moo-in-the-barn-1751.mp3";
import cowSound2 from "@/assets/sounds/mixkit-cow-sick-moo-1752.mp3";
import cowSound3 from "@/assets/sounds/mixkit-cow-moo-1744.mp3";

/**
 * ! Important when using images:
 * ! Change images path in data in the "u" property from `images/` to `/animations/main/images/`
 */
import backgroundAnimationData from "@/assets/animations/background/data.json";
import mainAnimationData from "@/assets/animations/main/data.json";

export default {
  name: "AnimationPlayground",
  components: {
    FoodDraggables,
    FeedMeCta,
  },
  setup() {
    const store = useStore();

    const { playAnimation, disableUi } = store;

    const backgroundAnimationEl = ref(null);
    const backgroundAnimation = ref(null);
    const mainAnimationEl = ref(null);
    const mainAnimation = ref(null);

    const state = reactive({
      currentlyPlayingSegment: null,
      mainAnimationSegments: {
        idle: [0, 120],
        food1: [121, 470],
        food2: [471, 820],
        food3: [821, 1170],
        food4: [1171, 1520],
        food5: [1521, 1871],
      },
    });
    const cowSound1Player = useSound(cowSound1);
    // const cowSound2Player = useSound(cowSound1);
    const cowSound3Player = useSound(cowSound2);
    const cowSound4Player = useSound(cowSound3);
    // const cowSound5Player = useSound(cowSound3);

    const playSound = (id) => {
      // console.log("play sound", id);
      if (store.soundEnabled) {
        if (id === "food1") cowSound1Player.play();
        if (id === "food2") cowSound1Player.play();
        if (id === "food3") cowSound3Player.play();
        if (id === "food4") cowSound4Player.play();
        if (id === "food5") cowSound4Player.play();
      }
    };

    const playSegments = (segmentId, force = true) => {
      // console.log("playSegments", segmentId);
      state.currentlyPlayingSegment = segmentId;
      playAnimation(segmentId);

      if (segmentId !== "idle") {
        disableUi();
        playSound(segmentId);
      }

      const segmentToPlay = state.mainAnimationSegments[segmentId];
      mainAnimation.value.playSegments(segmentToPlay, force);
    };

    const playIdleSegmentLoop = () => {
      playSegments("idle");
    };

    const onDropped = (event) => {
      // console.log(`${event} was dropped`);
      playSegments(event);
    };

    const resetToIdle = () => {
      playIdleSegmentLoop();
      emitter.emit("reset");
    };

    const loadBackgroundAnimation = () => {
      backgroundAnimation.value = new loadAnimation({
        container: backgroundAnimationEl.value,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: backgroundAnimationData,
      });
    };

    const loadMainAnimation = () => {
      mainAnimation.value = new loadAnimation({
        container: mainAnimationEl.value,
        renderer: "svg",
        // loop: true,
        autoplay: false,
        animationData: mainAnimationData,
      });
      mainAnimation.value.addEventListener("DOMLoaded", () =>
        playIdleSegmentLoop()
      );
      // mainAnimation.value.addEventListener("complete", (event) =>
      //   console.log("complete", { event })
      // );
      mainAnimation.value.addEventListener("loopComplete", () => {
        const segment = state.currentlyPlayingSegment;
        // console.log("loopComplete", { segment, event });
        if (segment !== "idle") {
          // console.log(
          //   "currently playing segment is not *idle*, will play idle"
          // );
          resetToIdle();
        }
      });
      // mainAnimation.value.addEventListener("enterFrame", () =>
      //   console.log("enterFrame")
      // );
      // mainAnimation.value.addEventListener("segmentStart", (event) =>
      //   console.log("segmentStart", { event })
      // );
      // mainAnimation.value.addEventListener("config_ready", () =>
      //   console.log("config_ready")
      // );
      mainAnimation.value.addEventListener("data_ready", () => {
        // console.log("data_ready");
        backgroundAnimation.value.setQuality("low");
      });
      // mainAnimation.value.addEventListener("DOMLoaded", () =>
      //   console.log("DOMLoaded")
      // );
      // mainAnimation.value.addEventListener("destroy", () =>
      //   console.log("destroy")
      // );
    };

    const loadAnimations = () => {
      loadBackgroundAnimation();
      backgroundAnimation.value.addEventListener("DOMLoaded", () =>
        loadMainAnimation()
      );
    };

    onMounted(() => {
      loadAnimations();
    });

    onBeforeUnmount(() => {
      backgroundAnimation.value.destroy();
      mainAnimation.value.destroy();
    });

    return {
      state,
      backgroundAnimationEl,
      mainAnimationEl,
      onDropped,
    };
  },
};
</script>

<style src="./AnimationPlayground.scss" lang="scss" scoped />
