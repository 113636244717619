<template>
  <div :class="$options.name">
    <div :class="`${$options.name}__wrapper`" ref="wrapper">
      <div
        v-for="item in draggableItems"
        :key="item.id"
        :id="item.id"
        :class="`draggable draggable--${item.id}`"
        :data-draggable="item.draggable"
        :data-droppable="item.droppable"
      >
        <div class="draggable-inner">
          <span class="draggable-content">{{ item.name }}</span>
        </div>
      </div>

      <div
        ref="dropzone"
        :class="[
          'dropzone',
          { 'drop-target': dropZoneIsTarget, 'drop-active': dropZoneIsActive },
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
import interact from "interactjs";
import { useStore } from "@/stores/main";
import { storeToRefs } from "pinia";
import emitter from "@/services/emitter";

export default {
  name: "FoodDraggables",
  setup() {
    const store = useStore();
    const { enableUi } = store;
    const { uiIsDisabled } = storeToRefs(store);
    return { uiIsDisabled, enableUi };
  },
  watch: {
    uiIsDisabled(isDisabled) {
      if (isDisabled) {
        // console.log("ui is disabled");
        this.interactDropzoneUnset();
        this.interactDraggablesUnset();
      } else {
        // console.log("gonna init");
        this.interactDropzoneInit();
        this.interactDraggablesInit();
      }
    },
  },
  data() {
    return {
      dropZoneIsActive: false,
      dropZoneIsTarget: false,
    };
  },
  computed: {
    draggableItems() {
      return [
        {
          id: "food1",
          name: this.$t("food.food1"),
          draggable: true,
          droppable: true,
          initalPositionInPercentage: {
            x: 10,
            y: 30,
          },
        },
        {
          id: "food2",
          name: this.$t("food.food2"),
          draggable: true,
          droppable: true,
          initalPositionInPercentage: {
            x: 13,
            y: 50,
          },
        },
        {
          id: "food3",
          name: this.$t("food.food3"),
          draggable: true,
          droppable: true,
          initalPositionInPercentage: {
            x: 75,
            y: 25,
          },
        },
        {
          id: "food4",
          name: this.$t("food.food4"),
          draggable: true,
          droppable: true,
          initalPositionInPercentage: {
            x: 85,
            y: 40,
          },
        },
        {
          id: "food5",
          name: this.$t("food.food5"),
          draggable: true,
          droppable: true,
          initalPositionInPercentage: {
            x: 78,
            y: 55,
          },
        },
      ];
    },
  },
  created() {
    emitter.on("reset", () => {
      this.initiallyPositionDraggableItems();
      this.enableUi();
    });
  },
  methods: {
    interactDropzoneInit() {
      // enable draggables to be dropped into this
      interact(this.$refs.dropzone).dropzone({
        // only accept elements matching this CSS selector
        accept: "[data-droppable=true]",

        // Require a 75% element overlap for a drop to be possible
        overlap: 0.75,

        // listen for drop related events:
        ondropactivate: () => {
          // add active dropzone feedback
          this.setDropZoneIsActive(true);
        },
        ondragenter: (event) => {
          const draggableElement = event.relatedTarget;
          // const dropzoneElement = event.target;

          // feedback the possibility of a drop
          this.setDropZoneIsTarget(true);

          draggableElement.classList.add("can-drop");
          // draggableElement.textContent = "Dragged in";
        },
        ondragleave: (event) => {
          // remove the drop feedback style
          this.setDropZoneIsTarget(false);

          event.relatedTarget.classList.remove("can-drop");
          // event.relatedTarget.textContent = "Dragged out";
        },
        ondrop: (event) => {
          // event.relatedTarget.textContent = "Dropped";
          // console.log(event);
          event.relatedTarget.classList.add("was-dropped");
          this.$emit("dropped", event.relatedTarget.id);
        },
        ondropdeactivate: () => {
          // remove active dropzone feedback
          this.setDropZoneIsActive(false);
          this.setDropZoneIsTarget(false);
        },
      });
    },
    interactDropzoneUnset() {
      interact(this.$refs.dropzone).unset();
    },
    interactDraggablesInit() {
      interact("[data-draggable=true]")
        .draggable({
          inertia: true,
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: "parent",
              endOnly: true,
            }),
          ],
          autoScroll: true,
          listeners: {
            move: this.dragMoveListener,
            onend: this.dragEndListener,
          },
        })
        .on("dragend", this.dragEndListener);
    },
    interactDraggablesUnset() {
      interact("[data-draggable=true]").unset();
    },
    async initiallyPositionDraggableItems(isInit) {
      await this.$nextTick();
      if (!isInit) {
        this.$el.querySelectorAll("[data-draggable=true]").forEach((el) => {
          el.classList.add("is-resetting");
        });
      }

      this.draggableItems.forEach((item) => {
        const { x, y } = item.initalPositionInPercentage;
        const draggable = document.getElementById(item.id);
        const draggableRect = draggable.getBoundingClientRect();
        const wrapperRect = this.$refs.wrapper.getBoundingClientRect();

        const xInPx = (x * wrapperRect.width - draggableRect.width / 2) / 100;
        const yInPx = (y * wrapperRect.height - draggableRect.height / 2) / 100;

        // console.log({ x, y, draggableRect, wrapperRect });
        // console.log({ xInPx, yInPx });

        draggable.style.transform = `translate(${xInPx}px, ${yInPx}px)`;
        draggable.setAttribute("data-x", xInPx);
        draggable.setAttribute("data-y", yInPx);
      });

      if (!isInit) {
        window.setTimeout(() => {
          this.$el.querySelectorAll("[data-draggable=true]").forEach((el) => {
            el.classList.remove("is-resetting");
            el.classList.remove("can-drop");
          });
        }, 300);
      }
    },

    onResize() {
      // console.log("window has been resized", event);
      this.initiallyPositionDraggableItems(true);
    },

    dragMoveListener(event) {
      const allDraggables = document.querySelectorAll("[data-draggable=true]");
      allDraggables.forEach((draggable) => {
        draggable.classList.remove("was-touched-last");
        draggable.classList.remove("was-dropped");
      });

      const target = event.target;
      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // translate the element
      target.style.transform = "translate(" + x + "px, " + y + "px)";

      target.classList.add("is-being-dragged");
      target.classList.add("was-touched-last");

      // update the posiion attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },

    dragEndListener(event) {
      // console.log("dragEndListener", event);
      const target = event.target;
      target.classList.remove("is-being-dragged");
    },

    setDropZoneIsActive(val) {
      this.dropZoneIsActive = val;

      if (val) {
        document.documentElement.classList.add("dropzone-is-active");
      } else {
        document.documentElement.classList.remove("dropzone-is-active");
      }
      // this.$emit("dropZoneIsActive", val);
    },

    setDropZoneIsTarget(val) {
      this.dropZoneIsTarget = val;

      if (val) {
        document.documentElement.classList.add("dropzone-is-target");
      } else {
        document.documentElement.classList.remove("dropzone-is-target");
      }
      // this.$emit("dropZoneIsTarget", val);
    },
  },
  mounted() {
    this.interactDropzoneInit();
    this.interactDraggablesInit();
    this.initiallyPositionDraggableItems(true);
  },
  beforeMount() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style src="./FoodDraggables.scss" lang="scss" scoped />
