<template>
  <div :class="$options.name">
    <h2 :class="`${$options.name}__title`">{{ $t("overviewTitle") }}</h2>
    <div
      :class="`${$options.name}__item ${$options.name}__item--${f}`"
      v-for="f in food"
      :key="f"
    >
      <h3 :class="`${$options.name}__item-title`">{{ $t(`food.${f}`) }}</h3>
      <img v-if="f === 'food1'" src="@/assets/overview/food1-heu.svg" />
      <img v-if="f === 'food2'" src="@/assets/overview/food2-gras.svg" />
      <img v-if="f === 'food3'" src="@/assets/overview/food3-kraftfutter.svg" />
      <img v-if="f === 'food4'" src="@/assets/overview/food4-misch-lein.svg" />
      <img v-if="f === 'food5'" src="@/assets/overview/food5-misch-hasel.svg" />
      <DashboardTitles overview />
    </div>
  </div>
</template>

<script>
import DashboardTitles from "@/components/DashboardTitles.vue";

export default {
  name: "OverviewContent",
  components: {
    DashboardTitles,
  },
  data() {
    return {
      food: ["food1", "food2", "food3", "food4", "food5"],
    };
  },
};
</script>

<style src="./OverviewContent.scss" lang="scss" scoped />
