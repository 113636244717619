<template>
  <div :class="[$options.name, { 'is-overview': overview }]">
    <div :class="`${$options.name}__wrapper`">
      <div :class="`${$options.name}__inner`">
        <div
          :class="`${$options.name}__label ${$options.name}__label--wellbeing`"
        >
          {{ $t("dashboards.wellbeing") }}
        </div>
        <div
          :class="`${$options.name}__label ${$options.name}__label--methane`"
        >
          {{ $t("dashboards.methane") }}
        </div>
        <div :class="`${$options.name}__label ${$options.name}__label--milk`">
          {{ $t("dashboards.milk") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardTitles",
  props: {
    overview: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style src="./DashboardTitles.scss" lang="scss" scoped />
