<template>
  <AnimationPlayground />
  <TheLogo />
  <div class="desktop-ui-controls">
    <MuteButton />
    <LangSwitcher />
  </div>
  <DashboardTitles />
  <div class="site-content">
    <div class="mobile-ui-controls">
      <MuteButton />
      <LangSwitcher />
    </div>
    <h1>{{ $t("title") }}</h1>
    <MainTextContent />
    <OverviewContent />
    <p style="margin-top: 2em">
      <router-link :to="{ name: `imprint-${$i18n.locale}` }">{{
        $t("imprint")
      }}</router-link>
    </p>
  </div>
</template>

<script>
import AnimationPlayground from "@/components/AnimationPlayground.vue";
import TheLogo from "@/components/TheLogo.vue";
import MuteButton from "@/components/MuteButton.vue";
import LangSwitcher from "@/components/LangSwitcher.vue";
import MainTextContent from "@/components/MainTextContent.vue";
import OverviewContent from "@/components/OverviewContent.vue";
import DashboardTitles from "@/components/DashboardTitles.vue";

export default {
  name: "ViewCow",
  components: {
    AnimationPlayground,
    TheLogo,
    MuteButton,
    LangSwitcher,
    MainTextContent,
    OverviewContent,
    DashboardTitles,
  },
};
</script>
