<template>
  <div class="site-content">
    <router-link :to="{ name: `home-${$i18n.locale}` }">{{
      $t("backToHome")
    }}</router-link>
    <h1 style="margin-top: 2em">{{ $t("imprint") }}</h1>
    <ImprintContent />
    <hr />
    <router-link :to="{ name: `home-${$i18n.locale}` }">{{
      $t("backToHome")
    }}</router-link>
  </div>
</template>

<script>
import ImprintContent from "@/components/ImprintContent.vue";

export default {
  name: "ViewImprint",
  components: {
    ImprintContent,
  },
};
</script>
