export default {
  de: {
    sound: {
      on: "Muh",
      off: "Muh",
    },
    cta: "Füttere mich",
    dashboards: {
      methane: "Methanausstoss",
      milk: "Milchproduktion",
      wellbeing: "Wohlbefinden",
    },
    food: {
      food1: "Heu",
      food2: "Gras",
      food3: "Kraftfutter",
      food4: "Mischration & Leinsamen",
      food5: "Mischration & Haselblätter",
    },
    title: "Die Klima-Kuh",
    overviewTitle: "Übersicht",
    imprint: "Impressum",
    backToHome: "Zurück zur Startseite",
  },
  en: {
    sound: {
      on: "UnMOOte",
      off: "MOOte",
    },
    cta: "Feed me",
    dashboards: {
      methane: "Methane emissions",
      milk: "Milk production",
      wellbeing: "Animal welfare",
    },
    food: {
      food1: "Hay",
      food2: "Grass",
      food3: "Concentrated feed",
      food4: "Mixed ration & linseed",
      food5: "Mixed ration & hazel leaves",
    },
    title: "The Climate Cow",
    overviewTitle: "Overview",
    imprint: "Imprint",
    backToHome: "Back to home page",
  },
  it: {
    sound: {
      on: "Mu",
      off: "Mu",
    },
    cta: "Dammi da mangiare",
    dashboards: {
      methane: "Emissioni di metano",
      milk: "Produzione di latte",
      wellbeing: "Benessere",
    },
    food: {
      food1: "Fieno",
      food2: "Erba",
      food3: "Mangime concentrato",
      food4: "Razione mista & semi di lino",
      food5: "Misto razione & foglie di nocciolo",
    },
    title: "La mucca del clima",
    overviewTitle: "Sommario",
    imprint: "Impronta",
    backToHome: "Torna alla pagina iniziale",
  },
  fr: {
    sound: {
      on: "Meuh",
      off: "Meuh",
    },
    cta: "Donne-moi à manger",
    dashboards: {
      methane: "Émissions de méthane",
      milk: "Production de lait",
      wellbeing: "Bien-être animal",
    },
    food: {
      food1: "Foin",
      food2: "Herbe",
      food3: "Aliments concentrés",
      food4: "Ration mélangée & graines de lin",
      food5: "Ration mélangée & feuilles de noisetier",
    },
    title: "La vache du climat",
    overviewTitle: "Aperçu",
    imprint: "Mentions légales",
    backToHome: "Retour à la page d'accueil",
  },
};
