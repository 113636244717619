<template>
  <div :class="$options.name">
    <div :class="`${$options.name}__inner`">
      {{ $t("cta") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedMeCta",
};
</script>

<style src="./FeedMeCta.scss" lang="scss" scoped />
