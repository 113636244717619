import { defineStore } from "pinia";

export const useStore = defineStore("main", {
  state: () => ({
    soundEnabled: false,
    uiIsDisabled: false,
    currentlyPlayingAnimation: null,
  }),
  actions: {
    playAnimation(id) {
      this.currentlyPlayingAnimation = id;

      // Add html classes
      if (id !== "idle") {
        document.documentElement.classList.add("is-playing-animation");
      } else {
        document.documentElement.classList.remove("is-playing-animation");
      }
    },
    enableUi() {
      this.uiIsDisabled = false;
    },
    disableUi() {
      this.uiIsDisabled = true;
    },
    toggleSound() {
      this.soundEnabled = !this.soundEnabled;
    },
  },
});
